var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"animated fadeIn",attrs:{"id":"app"}},[_c('b-card',[_c('div',{attrs:{"slot":"header"},slot:"header"},[_c('strong',{staticStyle:{"margin-left":"5px"}},[_vm._v("Select your Report")])]),_c('div',{staticClass:"custom-query-filters__wrapper"},[_c('div',{ref:"slider",staticClass:"custom-query-filters__grouped",attrs:{"id":"custom-query-filters"}},_vm._l((_vm.typesOptions),function(type){return _c('div',{key:type.id,class:{
                        'custom-query-filters__single': true,
                        'custom-query-filter__single--toggled': _vm.filter[type.id],
                    },on:{"click":function($event){return _vm.toggleFilter(type.id)}}},[(type.img)?_c('img',{staticClass:"custom-query-filters__single-img",attrs:{"src":type.img}}):_vm._e(),_vm._v(" "+_vm._s(type.label)+" ")])}),0)]),_vm._l((_vm.groupby(_vm.listFiltered)),function(dataProviders,category){return _c('div',{key:category},[_c('nav',{attrs:{"aria-label":"breadcrumb"}},[_c('ol',{staticClass:"breadcrumb"},[_c('li',{staticClass:"breadcrumb-item active",attrs:{"aria-current":"page"}},[_vm._v(_vm._s(category))])])]),_c('div',{staticClass:"custom-query-card__wrapper"},_vm._l((dataProviders),function(dataProvider){return _c('div',{key:dataProvider.id,staticClass:"custom-query-card",class:{
                        'custom-query-card--disabled':
                            _vm.$store.state.project.active.type === 'SANDBOX' && !dataProvider.sandbox,
                        'custom-query-card--has-short-desc':
                            dataProvider.preview && dataProvider.preview.shortDescription,
                    },attrs:{"data-name":dataProvider.name}},[_c(_vm.$store.state.project.active.type === 'SANDBOX' && !dataProvider.sandbox
                                ? 'div'
                                : 'project-router-link',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:(
                            _vm.$store.state.project.active.type === 'SANDBOX' && !dataProvider.sandbox
                                ? 'Not available in sandbox mode'
                                : ''
                        ),expression:"\n                            $store.state.project.active.type === 'SANDBOX' && !dataProvider.sandbox\n                                ? 'Not available in sandbox mode'\n                                : ''\n                        ",modifiers:{"hover":true}}],tag:"component",attrs:{"to":dataProvider.preview
                                ? `/reports/dataproviders/preview/${dataProvider.id}`
                                : `/reports/dataproviders/create/${dataProvider.id}`}},[_c('div',{staticClass:"custom-query-card__thumbnail"},[_c('img',{attrs:{"src":dataProvider.image || _vm.getImgUrl()}}),(dataProvider.preview && dataProvider.preview.shortDescription)?_c('div',{staticClass:"custom-query-card__short-description"},[_vm._v(" "+_vm._s(dataProvider.preview.shortDescription)+" ")]):_vm._e()]),_c('div',{staticClass:"custom-query-card__content"},[_c('div',{staticClass:"custom-query-card__content-left"},[_c('div',{staticClass:"custom-query-card__name"},[_vm._v(_vm._s(dataProvider.name))]),_c('div',{staticClass:"custom-query-card__categories"},[(dataProvider.connectors.length)?_c('span',[_vm._v(_vm._s(dataProvider.connectors[0].displayType))]):_vm._e(),(dataProvider.connectors.length > 1)?_c('span',[_vm._v(" + "+_vm._s(dataProvider.connectors.length - 1)+" more ")]):_vm._e()])]),_c('div',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",value:(
                                    dataProvider.connectors.length
                                        ? dataProvider.connectors.map(el => el.displayType).join('\n+ ')
                                        : null
                                ),expression:"\n                                    dataProvider.connectors.length\n                                        ? dataProvider.connectors.map(el => el.displayType).join('\\n+ ')\n                                        : null\n                                ",modifiers:{"hover":true}}],staticClass:"custom-query-card__content-right"},[(dataProvider.connectors.length)?_c('img',{attrs:{"src":dataProvider.connectors[0].image}}):_vm._e(),(dataProvider.connectors.length > 1)?_c('div',[_vm._v(" + "+_vm._s(dataProvider.connectors.length - 1)+" ")]):_vm._e()])])])],1)}),0)])})],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }