<template>
    <div id="app" class="animated fadeIn">
        <b-card>
            <div slot="header">
                <strong style="margin-left: 5px">Select your Report</strong>
            </div>

            <div class="custom-query-filters__wrapper">
                <div id="custom-query-filters" ref="slider" class="custom-query-filters__grouped">
                    <div
                        v-for="type in typesOptions"
                        :key="type.id"
                        :class="{
                            'custom-query-filters__single': true,
                            'custom-query-filter__single--toggled': filter[type.id],
                        }"
                        @click="toggleFilter(type.id)"
                    >
                        <img v-if="type.img" :src="type.img" class="custom-query-filters__single-img" />
                        {{ type.label }}
                    </div>
                </div>
            </div>

            <div v-for="(dataProviders, category) in groupby(listFiltered)" :key="category">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item active" aria-current="page">{{ category }}</li>
                    </ol>
                </nav>
                <div class="custom-query-card__wrapper">
                    <div
                        class="custom-query-card"
                        v-for="dataProvider in dataProviders"
                        :key="dataProvider.id"
                        :data-name="dataProvider.name"
                        v-bind:class="{
                            'custom-query-card--disabled':
                                $store.state.project.active.type === 'SANDBOX' && !dataProvider.sandbox,
                            'custom-query-card--has-short-desc':
                                dataProvider.preview && dataProvider.preview.shortDescription,
                        }"
                    >
                        <component
                            :is="
                                $store.state.project.active.type === 'SANDBOX' && !dataProvider.sandbox
                                    ? 'div'
                                    : 'project-router-link'
                            "
                            :to="
                                dataProvider.preview
                                    ? `/reports/dataproviders/preview/${dataProvider.id}`
                                    : `/reports/dataproviders/create/${dataProvider.id}`
                            "
                            v-b-tooltip.hover="
                                $store.state.project.active.type === 'SANDBOX' && !dataProvider.sandbox
                                    ? 'Not available in sandbox mode'
                                    : ''
                            "
                        >
                            <div class="custom-query-card__thumbnail">
                                <img :src="dataProvider.image || getImgUrl()" />
                                <div
                                    class="custom-query-card__short-description"
                                    v-if="dataProvider.preview && dataProvider.preview.shortDescription"
                                >
                                    {{ dataProvider.preview.shortDescription }}
                                </div>
                            </div>
                            <div class="custom-query-card__content">
                                <div class="custom-query-card__content-left">
                                    <div class="custom-query-card__name">{{ dataProvider.name }}</div>
                                    <div class="custom-query-card__categories">
                                        <span v-if="dataProvider.connectors.length">{{
                                            dataProvider.connectors[0].displayType
                                        }}</span>
                                        <span v-if="dataProvider.connectors.length > 1">
                                            + {{ dataProvider.connectors.length - 1 }} more
                                        </span>
                                    </div>
                                </div>
                                <div
                                    class="custom-query-card__content-right"
                                    v-b-tooltip.hover="
                                        dataProvider.connectors.length
                                            ? dataProvider.connectors.map(el => el.displayType).join('\n+ ')
                                            : null
                                    "
                                >
                                    <img
                                        v-if="dataProvider.connectors.length"
                                        :src="dataProvider.connectors[0].image"
                                    />
                                    <div v-if="dataProvider.connectors.length > 1">
                                        + {{ dataProvider.connectors.length - 1 }}
                                    </div>
                                </div>
                            </div>
                        </component>
                    </div>
                </div>
            </div>
        </b-card>
    </div>
</template>

<script>
import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

import {collectTypes} from '@/shared/collectTypes'
import {processTypes} from '@/shared/processTypes'
import {reportTypes} from '@/shared/reportTypes'

export default {
    components: {
        Treeselect,
    },
    data() {
        return {
            list: [],
            filter: {},
        }
    },
    created() {
        this.fetchDataProviderCategories()
    },
    computed: {
        types() {
            return [...collectTypes, ...processTypes, ...reportTypes]
        },
        typesOptions() {
            return this.types
                .map(el => {
                    const id = `${el.type} ${el.subtype}`
                    const reportFound = this.list.some(report =>
                        report.connectors.some(connector => connector.type === id)
                    )

                    if (reportFound) {
                        return {id, label: el.subtype, img: require(`@/assets/${el.img}`)}
                    } else {
                        return null
                    }
                })
                .filter(Boolean)
        },
        listFiltered() {
            return Object.keys(this.filter).some(key => this.filter[key])
                ? this.list.filter(el => el.connectors.findIndex(connector => this.filter[connector.type]) > -1)
                : this.list
        },
    },
    methods: {
        fetchDataProviderCategories() {
            this.$store.commit('loading/PROCESSING', `Fetching available reports...`)
            this.axios
                .get(`${process.env.VUE_APP_NODE_API_HOST}/customQuery/project/${this.$store.state.project.active.id}`)
                .then(response => {
                    this.list = response.data.data.map(dataProvider => {
                        const connectors = []

                        if (dataProvider.preview) {
                            const _connectors = [
                                ...dataProvider.preview.requiredConnectors,
                                ...dataProvider.preview.optionalConnectors,
                            ]

                            connectors.push(
                                ..._connectors.map(connector => {
                                    const type = this.types.find(el => `${el.type} ${el.subtype}` === connector.type)

                                    return {
                                        ...connector,
                                        displayType: type.subtype,
                                    }
                                })
                            )
                        }

                        return {...dataProvider, connectors}
                    })
                    this.filter = this.typesOptions.reduce((acc, curr) => ({...acc, [curr.id]: false}), {})

                    this.$store.commit('loading/PROCESSED')
                })
                .catch(exception => {
                    this.$store.commit('loading/PROCESSED')
                    this.dataStudioError.state = false
                    this.dataStudioError.message = exception.response.data.additionalInfo
                })
        },
        getImgUrl(pic) {
            return require('@/assets/reports/custom.png')
        },
        groupby(array) {
            return array.reduce(
                (h, obj) =>
                    Object.assign(h, {
                        [obj.category || 'General']: (h[obj.category || 'General'] || []).concat(obj),
                    }),
                {}
            )
        },
        toggleFilter(id) {
            this.filter[id] = !this.filter[id]
        },
    },
}
</script>

<style lang="scss">
div#app {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .search-wrapper {
        position: relative;
        label {
            position: absolute;
            font-size: 12px;
            color: rgba(0, 0, 0, 0.5);
            top: 8px;
            left: 12px;
            z-index: -1;
            transition: 0.15s all ease-in-out;
        }
        input {
            padding: 4px 12px;
            color: rgba(0, 0, 0, 0.7);
            border: 1px solid rgba(0, 0, 0, 0.12);
            transition: 0.15s all ease-in-out;
            background: white;
            &:focus {
                outline: none;
                transform: scale(1.05);
                & + label {
                    font-size: 10px;
                    transform: translateY(-24px) translateX(-12px);
                }
            }
            &::-webkit-input-placeholder {
                font-size: 12px;
                color: rgba(0, 0, 0, 0.5);
                font-weight: 100;
            }
        }
    }

    .card {
        width: 100%;
    }

    .wrapper {
        display: flex;
        flex-wrap: wrap;
        padding-top: 12px;
    }

    .custom-query-card__wrapper {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, max-content));
        padding: initial;
        grid-gap: 30px;
        margin: 0 12px 10px;

        .custom-query-card {
            width: 300px;
            height: 185px;
            box-shadow: rgba(0, 0, 0, 0.117647) 0px 1px 6px, rgba(0, 0, 0, 0.117647) 0px 1px 4px;
            border-radius: 5px;

            .custom-query-card__thumbnail {
                position: relative;
                overflow: hidden;

                img {
                    object-fit: cover;
                    width: 300px;
                    height: 120px;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.117647);
                    border-top-left-radius: 5px;
                    border-top-right-radius: 5px;
                }

                .custom-query-card__short-description {
                    width: 300px;
                    height: 120px;
                    border-top-left-radius: 5px;
                    border-top-right-radius: 5px;
                    background-color: #20a8d8;
                    color: white;
                    padding: 10px 15px;

                    position: absolute;
                    top: 100%;
                    left: 0;
                    transition: top 0.3s ease-out;
                }
            }

            a,
            a:hover {
                text-decoration: none;
            }

            .custom-query-card__content {
                display: flex;
                flex-flow: row nowrap;
                justify-content: space-between;

                padding: 10px 15px;
                width: 100%;
                height: 65px;
                color: #4b4b4c;

                .custom-query-card__content-left {
                    flex-grow: 1;
                    max-width: calc(100% - 56px);
                }

                .custom-query-card__name {
                    font-weight: 600;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    max-height: 1.32rem;
                    max-width: calc(100% - 56px);
                }

                .custom-query-card__content-right {
                    position: relative;
                    padding: 5px 10px;
                    height: 100%;
                    place-self: center;

                    img {
                        height: 36px;
                        object-fit: contain;
                        display: block;
                    }

                    div {
                        position: absolute;
                        top: 2.5px;
                        right: 0;
                        padding: 2.5px;

                        text-align: center;
                        font-size: 0.675rem;
                        color: white;
                        font-weight: 600;
                        border-radius: 50%;
                        background-color: #ffc32b;
                    }
                }
            }

            &--has-short-desc {
                .custom-query-card__thumbnail:hover {
                    .custom-query-card__short-description {
                        top: 0;
                    }
                }
            }
        }
    }

    .custom-query-filters__wrapper {
        position: relative;
        white-space: wrap;
        touch-action: pan-y;
        margin: 0 auto 30px;

        display: flex;
        flex-direction: row;
        overflow: hidden;

        .custom-query-filters__grouped {
            position: relative;
            -webkit-overflow-scrolling: touch;
            display: inline-block;
            white-space: wrap;

            -ms-overflow-style: none; /* for Internet Explorer, Edge */
            scrollbar-width: none; /* for Firefox */
            // overflow-y: scroll;
            &::-webkit-scrollbar {
                display: none; /* for Chrome, Safari, and Opera */
            }

            .custom-query-filters__single {
                display: inline-flex;

                align-items: center;
                padding: 7.5px;
                border-radius: 5px;
                border: 1px solid rgb(255, 195, 43);
                cursor: pointer;

                margin: 5px;

                img {
                    margin-right: 5px;
                    height: 24px;
                }

                &.custom-query-filter__single--toggled {
                    background-color: #ffdb80;
                }
            }
        }
    }
}
</style>
